@keyframes dropmenu-fadeIn {
  0% {
    z-index: 999;
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    z-index: 999;
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes dropmenu-fadeOut {
  0% {
    z-index: 999;
    opacity: 1;
    transform: scale(1);
  }
  99% {
    z-index: 999;
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    z-index: -999;
    opacity: 0;
  }
}

.dropmenu-btn {
  height: 40px;
  width: 40px;
  opacity: 0.7;
  transition: opacity 100ms;
  border-radius: 20px;
}
.dropmenu-btn:hover {
  opacity: 0.9;
  transition: opacity 100ms;
}
.dropmenu-btn-opened {
  opacity: 1 !important;
  background-color: #444 !important;
}
.dropmenu {
  position: absolute;
  min-height: 20px;
  min-width: 100px;
  background-color: #333;
  z-index: 900;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  border-radius: 7px;
}
.dropmenu-visible {
  animation: dropmenu-fadeIn 50ms ease-out;
  z-index: 999;
  opacity: 1;
}
.dropmenu-hidden {
  animation: dropmenu-fadeOut 50ms ease-in;
  z-index: -999;
  opacity: 0;
}
.dropmenu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
  background-color: #333;
  transition: background-color 100ms;
  padding: 5px 15px;
  gap: 10px;
}
.dropmenu-item:hover {
  background-color: #444;
  transition: background-color 100ms;
}
.dropmenu-item:active {
  background-color: #666;
  transition: background-color 100ms;
}
.dropmenu-item-label {
  color: #ddd;
  font-size: 16px;
}
.dropmenu-item-icon {
  width: 20px;
  height: 20px;
}
.dropmenu-separator {
  height: 1px;
  width: 100%;
  background-color: #555;
  margin: 4px 0px;
}