.calendar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.calendar td {
  box-sizing: border-box;
  padding: 5px;
  border-radius: 5px;
}
.calendar th {
  height: 30px;
}
.calendar-weekday {
  background-color: #333;
}
.calendar-weekend {
  background-color: #2d2d2d;
}

.calendar-active {
  transition: 100ms;
}
.calendar-active:hover, .calendar-active:active {
  background-color: #444;
}
.calendar-active:active {
  opacity: 0.5;
}
.calendar-inactive {
  opacity: 0.25;
}
.calendar-today {
  border: 1px solid #ccc !important;
}

.calendar-cell {
  width: 140px;
  height: 100px;
  display: flex;
  flex-direction: column;
}
.calendar-cell-top {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.calendar-cell-date {
  justify-self: start;
  align-self: start;
  margin-left: 10px;
  font-size: 26px;
}
.calendar-cell-icon {
  margin-left: auto;
  align-self: start;
  margin-right: 5px;
  margin-top: 5px;
  width: 30px;
  height: 30px;
}
.calendar-cell-bottom {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 5px;
  gap: 10px;
}
.calendar-event {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
}
div.calendar-event {
  font-size: 24px;
  font-weight: bold;
  background-color: #222;
  color: #888;
  text-align: center;
}