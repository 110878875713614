.title-bar {
  background-color: #333;
  height: 50px;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  gap: 15px;
}
.title-bar-text {
  font-size: 24px;
  font-weight: bold;
}
.title-bar-back-btn {
  margin: 3px 0 0 0;
  padding: 10px;
  width: 24px;
  background-color: transparent;
  border-radius: 50px;
  opacity: 1;
  transition: 100ms;
}
.title-bar-back-btn:hover, .title-bar-back-btn:active {
  background-color: rgba(255,255,255,0.1);
}
.title-bar-back-btn:active {
  opacity: 0.5;
}