
.toggle-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}
.toggle-label {
  color: #ddd;
  transition: 100ms;
}
.toggle-label:hover, .toggle-label:active {
  color: #fff;
}
.toggle-label:active {
  opacity: 0.5;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #777;
  transition: 100ms;
  border-radius: 28px;
}
.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: #222;
  transition: 100ms;
  border-radius: 50%;
}
input:checked+.toggle-slider {
  background-color: #ed8424;
}
input:checked+.toggle-slider:before {
  transform: translateX(22px);
}
