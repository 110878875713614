.tday-routines-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}
.tday-routine {
  background-color: #282828;
  border-radius: 10px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tday-tasks-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
}
.tday-task {
  background-color: #333;
  border-radius: 10px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.tday-task-updown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.tday-task-updown {
  padding: 0;
  width: 40px;
  height: 30px;
}