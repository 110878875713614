.colsel-container {
  display: flex;
  flex-direction: row;
}
.colsel-item {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  opacity: 0.5;
  transition: 100ms;
}
.colsel-item:hover {
  opacity: 0.75;
}
.colsel-item:active {
  opacity: 0.25;
}
.colsel-item-selected {
  border: 5px solid white;
  opacity: 1;
}