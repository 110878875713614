/* General */
@font-face {
  font-family: "Segoe UI";
  src: url(./fonts/segoe-ui.ttf) format("truetype");
}
@font-face {
  font-family: "SpaceMonoRegular";
  src: url(./fonts/spacemono-regular.ttf) format("truetype");
}
* {
  outline: none;
}
html, body, #root {
  font-family: 'Segoe UI', sans-serif;
  margin: 0;
  background-color: #222;
  color: #ddd;
  width: 100%;
  height: 100%;
  font-size: 18px;
}
.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; 
}

/* Row System */
.flex-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.flex-col-0 { flex: 0; overflow: hidden; }
.flex-col-1 { flex: 1; overflow: hidden; }
.flex-col-2 { flex: 2; overflow: hidden; }
.flex-col-3 { flex: 3; overflow: hidden; }
.flex-col-4 { flex: 4; overflow: hidden; }
.flex-col-5 { flex: 5; overflow: hidden; }
.flex-col-6 { flex: 6; overflow: hidden; }
.flex-col-7 { flex: 7; overflow: hidden; }
.flex-col-8 { flex: 8; overflow: hidden; }
.flex-col-9 { flex: 9; overflow: hidden; }

/* Text */
h1, h2, h3, p { margin: 0; }
h1 { font-size: 35px; }
h2 { font-size: 27px; }
h3 { font-size: 21px; }
.err {
  color: #e66;
}
label {
  line-height: 30px;
}

/* Link */
a {
  color: #ff8e26;
  transition: color 100ms;
  transition: opacity 100ms;
}
a:hover {
  color: #feba7a;
  transition: color 100ms;
}
a:active {
  opacity: 0.5;
  transition: opacity 100ms;
}

/* Button */
button, input[type=button] {
  background-color: #444;
  color: #eee;
  border: none;
  font-size: 16px;
  padding: 0 20px;
  height: 36px;
  transition: 100ms;
  transition: background-color 100ms;
  transition: opacity 100ms;
  border-radius: 5px;
  /* noselect */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button.danger, 
input[type=button].danger {
  background-color: #711;
}
button.danger:hover, 
input[type=button].danger:hover, 
button.danger:focus,
input[type=button].danger:focus {
  background-color: #822;
}

button.primary,
input[type=button].primary {
  background-color: #b1661f;
}
button.primary:hover,
input[type=button].primary:hover,
button.primary:focus,
input[type=button].primary:focus {
  background-color: #9d5818;
}

button:hover, input[type=button]:hover, button:focus, input[type=button]:focus {
  outline: none;
  background-color: #555;
  transition: background-color 100ms;
}
button:active, input[type=button]:active {
  opacity: 0.5;
  transition: opacity 100ms;
}
.btnimg, .btnimg-solo {
  height: 20px;
  vertical-align: sub;
  margin-right: 10px;
  /* noselect */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btnimg-solo {
  margin-right: 0;
}

/* Text Input */
input[type=text],
input[type=password],
input[type=number] {
  background-color: #111;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #444;
  color: #bbb;
  padding: 0 5px;
  transition: 100ms;
  font-size: 18px;
  transition: border-color 100ms;
  height: 32px;
}
input[type=text]:hover,
input[type=password]:hover,
input[type=number]:hover {
  background-color: #111;
  border: 1px solid #666;
  transition: border-color 100ms;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=number]:focus {
  outline: none;
  background-color: #111;
  border: 1px solid #ed8424; /* #888 */
  transition: border-color 100ms;
}

/* Hide spin arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type=number] {
  appearance: textfield;
}

/* Scrollbar */
::-webkit-scrollbar {/* width */
  width: 15px;
}
::-webkit-scrollbar-track {/* Track */
  background-color: #1a1a1a;
}
::-webkit-scrollbar-thumb {/* Handle */
  background-color: #444;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {/* Handle on hover */
  background-color: #555;
}