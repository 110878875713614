@keyframes content-fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  animation: content-fadeIn 250ms;
}
.content-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.content-centered-box {
  background-color: #333;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 25px rgba(0,0,0,0.25);
}