.templates-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 400px;
}
.templates-header button {
  height: 30px;
  margin-top: 5px;
}
.templates-list {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.templates-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.templates-item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  border-radius: 10px;
  transition: 100ms;
  min-width: 0;
}
.templates-item-details:hover, .templates-item-details:active {
  background-color: #444;
}
.templates-item-details:active {
  opacity: 0.5;
}
.templates-item-icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.templates-item-name {
  font-size: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.templates-item-menu {
  margin-left: auto;
}