.stars-history {
  width: 700px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.stars-history-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  border-radius: 10px;
}
.stars-history-item-icon {
  font-size: 21px;
  width: 18px;
  height: 18px;
}
.stars-history-item-amount {
  font-size: 21px;
  width: 100px;
}
.stars-history-item-source {
  color: #999;
  overflow: hidden;
}
.stars-history-item-date {
  color: #bbb;
  margin-left: auto;
}
.stars-history-item-menu {
  margin-left: 20px;
}