@keyframes popup-fadeIn {
  0% {
    z-index: 200;
    opacity: 0;
  }
  100% {
    z-index: 200;
    opacity: 1;
  }
}
@keyframes popup-fadeOut {
  0% {
    z-index: 200;
    opacity: 1;
  }
  99% {
    z-index: 200;
    opacity: 0;
  }
  100% {
    z-index: -200;
    opacity: 0;
  }
}

.popup-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-show {
  opacity: 1;
  z-index: 200;
  animation: popup-fadeIn 100ms ease-in;
}
.popup-hide {
  opacity: 0;
  z-index: -200;
  animation: popup-fadeOut 100ms ease-out;
}
.popup-wrapper {
  background-color: #333;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 30px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
}