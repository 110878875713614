@keyframes spinner-overlay-fadeIn {
  0% {
    background-color: rgba(34,34,34,0);
    z-index: 999;
    opacity: 0;
  }
  20% {
    background-color: rgba(34,34,34,0);
    z-index: 999;
    opacity: 1;
  }
  80% {
    background-color: rgba(34,34,34,0);
    z-index: 999;
    opacity: 1;
  }
  100% {
    z-index: 999;
    opacity: 1;
    background-color: rgba(34,34,34,0.5);
  }
}
@keyframes spinner-overlay-fadeOut {
  0% {
    background-color: rgba(34,34,34,0);
    z-index: 999;
    opacity: 1;
  }
  99% {
    z-index: 999;
    opacity: 0;
  }
  100% {
    z-index: -999;
    opacity: 0;
  }
}

.spinner-overlay {
  background-color: rgba(34,34,34,0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: -999;

  display: flex;
  opacity: 0;
}
.spinner-overlay-show {
  animation: spinner-overlay-fadeIn 1000ms ease;
  background-color: rgba(34,34,34,0.5);
  z-index: 999;
  opacity: 1;
}
.spinner-overlay-hide {
  animation: spinner-overlay-fadeOut 100ms ease;
  background-color: rgba(34,34,34,0);
  z-index: -999;
  opacity: 0;
}

@keyframes spinner-indicator-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner-indicator {
  animation: spinner-indicator-rotation 1s infinite linear;
  width: 64px;
}
