.daydisplay-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.daydisplay-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.daydisplay-item {
  align-self: stretch;
  border-radius: 10px;
  padding: 20px 20px;
  color: #eee;
  transition: background-color 100ms;
}
/*.daydisplay-item:hover {
  background-color: #333;
  transition: background-color 100ms;
}*/
.daydisplay-passed .daydisplay-item-details,
.daydisplay-passed .daydisplay-item-tasks,
.daydisplay-passed .daydisplay-item-icon,
.daydisplay-passed .daydisplay-item-relative {
  opacity: 0.5;
}
.daydisplay-next {
  border: 1px solid #9d5a1b; /* #555 */
  box-sizing: border-box;
}
/*.daydisplay-inprogress {}*/
.daydisplay-routine {
  background-color: #2d2d2d;
}
.daydisplay-event {
  background-color: #282828;
}
.daydisplay-item-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.daydisplay-item-details {
  margin-left: 12px;
}
.daydisplay-item-icon {
  height: 50px;
  width: 50px;
}
.daydisplay-item-controls {
  margin-left: auto;
  justify-self: end;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.daydisplay-item-indicator {
  width: 40px;
  height: 40px;
}
.daydisplay-item-relative {
  font-size: 14px;
  color: #aaa;
}
.daydisplay-item-tasks {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 40px;
}
.daydisplay-item-task {
  border-radius: 7px;
  border: 2px solid #555;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.daydisplay-item-task-done {
  border-color: #4e4;
  width: 40px;
  height: 40px;
}
.daydisplay-item-task-missed {
  border-color: #e44;
  width: 40px;
  height: 40px;
}
.daydisplay-item-task-icon {
  width: 100%;
  height: 100%;
}
.daydisplay-item-task-icon-inactive {
  opacity: 0.5;
}
.daydisplay-item-star {
  width: 30px;
  height: 30px;
  justify-self: flex-start;
  margin-right: auto;
  margin-left: 10px;
}
