.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.app-title {
  height: 50px;
  padding: 10px 25px 10px 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  text-decoration: none;
}
.app-title-logo {
  height: 35px;
}
.app-title-text {
  font-size: 27px;
  font-weight: bold;
  color: #eee;
  margin-bottom: 2px;
}
.page {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
}