.tdaysel-container {
  display: flex;
  flex-direction: row;
  height: 32px;
}
.tdaysel-rightbtn {
  display: block;
  height: 100%;
  padding: 0;
  min-width: 50px;
}
.tdaysel-input {
  display: block;
  height: 100%;
  min-width: 100px;
  flex-grow: 1;
  text-align: center;
}

.tdaysel-list {
  width: 290px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tdaysel-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #444;
  padding: 10px 20px;
  border-radius: 10px;
  transition: 100ms;
}
.tdaysel-item:hover,
.tdaysel-item:active {
  background-color: #555;
}
.tdaysel-item:active {
  opacity: 0.5;
}
.tdaysel-item-icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.tdaysel-item-name {
  font-size: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}