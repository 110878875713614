.icons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 500px;
  gap: 20px;
}
.icons-item {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
  transition: 100ms;
  border-radius: 10px;
  overflow: hidden;
}
.icons-item:active {
  opacity: 0.5;
  transition: 100ms;
}
.icons-delete {
  opacity: 0;
  transition: 100ms;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-delete:hover, .icons-item:active .icons-delete {
  opacity: 1;
  transition: 100ms;
}
.icons-delete img {
  width: 32px;
  height: 32px;
}