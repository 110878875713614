.dursel-container {
  display: flex;
  flex-direction: row;
  height: 32px;
}
.dursel-leftbtn, .dursel-rightbtn {
  display: block;
  height: 100%;
  padding: 0;
  min-width: 50px;
}
.dursel-input {
  display: block;
  height: 100%;
  min-width: 100px;
  flex-grow: 1;
  text-align: center;
}