.side-menu {
  width: 200px;
  height: 100%;
  flex-grow: 0;
  background-color: #444;
  overflow: auto;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.side-menu-item {
  color: #eee;
  text-decoration: none;
  font-size: 18px;
  background-color: #444;
  padding: 5px 20px 5px 20px;
  transition: opacity 100ms;
  transition: background-color 100ms;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.side-menu-item:hover {
  background-color: #555;
  color: #eee;
  transition: background-color 100ms;
}
.side-menu-item:active {
  opacity: 0.5;
  transition: opacity 100ms;
}
.side-menu-item.active {
  background-color: #666;
}
.side-menu-separator {
  height: 2px;
  background-color: #333;
}
.side-menu-spacer {
  height: 10px;
}