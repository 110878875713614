.icosel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.icosel-icon {
  border: 1px solid rgba(255,255,255,0.1);
}
.icosel-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 350px;
}
.icosel-list-item {
  width: 50px;
  height: 50px;
  transition: 100ms;
}
.icosel-list-item:hover {
  opacity: 0.75;
}
.icosel-list-item:active {
  opacity: 0.5;
}